@import 'select2';

@import '../../defaults/mixins';
@import '../../defaults/typography';

section#search {
  position: relative;
  margin-bottom: -1px;
  padding: 0;
  overflow: hidden;
  background-position: center;

  display: flex;
  flex-direction: column;
  align-items: center;

  aspect-ratio: 1920 / 833;

  @include breakpoint-down-xs {
    aspect-ratio: 2 / 3;
  }

  .banner-link {
    position: absolute;
    inset: 0;
  }

  img, video {
    position: relative;
    z-index: -1;

    margin-top: -1px;
    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: center;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: var(--black-overlay);
    pointer-events: none;
  }

  .filters {
    z-index: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    width: 582px;
    margin: auto;

    @include breakpoint-down-xs {
      padding: var(--space-default) var(--space-s);
      width: 100%;
    }
  }

  .keywords-filter {
    position: relative;
    margin-bottom: var(--space-default);

    #mixedSearch {
      width: 100%;

      & + .select2 {
        background-color: var(--white);
        border-radius: 20px;
        padding: var(--space-xs) var(--space-l) var(--space-xs) calc(var(--space-default) * 1.5);

        .select2-selection--single {
          height: auto;

          &:focus {
            outline: none;
          }
        }

        .select2-selection__rendered {
          width: 100%;
          display: flex;
          align-items: center;
          gap: var(--space-s);
          padding-left: 0;

          &:has(.select2-selection__choice) {
            padding-left: var(--space-xs);
          }
        }

        &.select2-container--open {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }

        .select2-selection__placeholder {
          color: var(--color-placeholder);
        }

        .select2-selection__choice {
          background-color: var(--gray-2);
          padding: 0 var(--space-s) 0 var(--space-xxs);
          border-radius: 12px;

          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        .select2-selection__choice__remove {
          margin-right: var(--space-xxs);
          display: inline-block;
          height: 16px;
          line-height: 18px;
          width: 16px;
          border-radius: 50%;
          background-color: var(--gray-3);
          text-align: center;
        }

        .select2-search--inline {
          flex-grow: 1;
        }

        .select2-selection__choice::marker, .select2-search--inline::marker {
          color: transparent;
        }
      }
    }

    .select2-container--open:has(#select2-mixedSearch-results) {
      top: 40px !important;
    }

    i.iconoir-search {
      position: absolute;
      top: 50%;
      right: calc(var(--space-default) * 1.5);
      transform: translateY(-50%);
      font-size: calc(var(--text-base-size) * 1.5);
    }
  }

  .secondary-filters {
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-xs);

    .select2 {
      background-color: rgba(255, 255, 255, .8);
      border-radius: 16px;
      width: auto !important;

      &.select2-container--open {
        background-color: var(--white);
        border-radius: 14px 14px 0 0;
        min-width: 190px;
      }
    }

    .select2-selection__rendered {
      padding: var(--space-xxs) var(--space-default) calc(var(--space-unit) / 2);
    }

    .select2-selection {
      height: auto;

      @include text-label-medium;
      text-transform: uppercase;
    }

    .select2-selection__placeholder {
      display: flex;
      justify-content: space-between;

      &::after {
        content: '';
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"><path d="M3 4.5L6 7.5L9 4.5" stroke="%23212121" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');

        display: inline-block;
        vertical-align: top;

        width: 12px;
        height: 12px;
        margin-left: var(--space-xs);
      }
    }

    #typeFilterContainer {
      position: relative;

      .select2-container--open:has(#select2-type-results) {
        top: 24px !important;
      }
    }

    #seasonFilterContainer {
      position: relative;

      .select2-container--open:has(#select2-season-results) {
        top: 24px !important;
      }
    }

    #cityFilterContainer {
      position: relative;

      .select2-container--open:has(#select2-city-results) {
        top: 24px !important;
      }
    }

    #brandFilterContainer {
      position: relative;

      .select2-container--open:has(#select2-designer-results) {
        top: 24px !important;
      }
    }
  }

  .banner-metadata {
    text-align: center;
    position: absolute;
    bottom: 0;
  }

  .title {
    font-family: var(--font-primary);
    color: var(--white);
    font-size: calc(var(--text-base-size) * 2.5);
    font-weight: 400;
    z-index: 1;
  }

  .subtitle {
    font-family: var(--font-primary);
    color: var(--white);
    font-size: calc(var(--text-base-size) * 1.375);
    font-weight: 300;

    z-index: 1;
    margin-top: calc(var(--space-default) * -1);
    margin-bottom: var(--space-xxs);
  }

  .title a, .subtitle a {
    text-decoration: none;
  }
}

.cta-banner {
  background-color: black;
  color: white;
  text-align: center;
  font-weight: 400;
  padding: var(--space-xs);
}

.select2-dropdown {
  border: none;
  padding-bottom: var(--space-default);
}

#select2-mixedSearch-results {
  @include text-body-medium;
  text-transform: uppercase;
  color: var(--gray-8);

  max-height: 227px;
  overflow-y: scroll;
  margin-top: -1px;

  .select2-results__option {
    padding: var(--space-xxs) var(--space-default);

    &:hover {
      background-color: var(--gray-1);
    }
  }

  .select2-results__option--highlighted {
    background-color: var(--gray-1);
    border-radius: 20px;
  }
}

.select2-dropdown:has(#select2-mixedSearch-results) {
  border-radius: 20px;
  top: -40px;
  background: transparent;

  .select2-search__field, .select2-search__field:focus {
    border: none;
    outline: none;
    background: transparent;
    padding: var(--space-unit) var(--space-l) var(--space-unit) calc(var(--space-default) * 1.25);
  }

  .select2-results {
    background-color: var(--white);
    border-radius: 0 0 20px 20px;
  }
}

.select2-results__options {
  @include scrollbar;
}

.select2-dropdown:has(#select2-type-results),
.select2-dropdown:has(#select2-season-results),
.select2-dropdown:has(#select2-city-results),
.select2-dropdown:has(#select2-designer-results) {
  padding-bottom: 0;
  border-radius: 0 0 14px 14px;

  .select2-search--dropdown {
    padding: 0 var(--space-s);
  }

  .select2-results {
    padding: 0 var(--space-s);

    text-transform: uppercase;
    @include text-label-medium;

    .select2-results__options {
      max-height: 200px;
      overflow-y: scroll;
      margin: var(--space-xxs) 0;
    }
  }
}
