@import '../../defaults/typography';

.trend-card {
  position: relative;
  background-color: var(--black);

  img {
    opacity: .6;
    width: 100%;
  }

  h3 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    padding: var(--space-xs);

    color: var(--white);
    text-transform: capitalize;
    text-align: center;
  }

  label {
    position: absolute;
    bottom: var(--space-s);
    left: 50%;
    transform: translateX(-50%);

    @include text-label-medium;
    color: var(--white);
    text-transform: capitalize;
    text-align: center;
  }
}
