@import '../base';

@import 'components/poll';
@import 'components/search';

@import '../trend/components/card';

.tds #menu {
  margin-bottom: 0;
}

.tds section {
  margin-bottom: var(--space-xxl);
  padding-inline: calc(var(--space-default) * 5.25);

  @include breakpoint-down-s {
    margin-bottom: var(--space-xl);
    padding-inline: var(--space-m);

    *[class*=col-] {
      grid-column: auto / span 12;
    }

    .poll {
      margin-bottom: calc(var(--space-default) * 1.25);
    }
  }

  @include breakpoint-down-xs {
    padding-inline: var(--space-default);

    .poll {
      margin-bottom: var(--space-default);
    }
  }

  h2 {
    text-align: center;
    margin-bottom: calc(var(--space-default) * 1.5);
    text-transform: capitalize;

    @include breakpoint-down-xs {
      margin-bottom: var(--space-s);
    }

    a {
      text-decoration: none;
    }
  }

  .subtitle {
    @include text-body-large;
    text-align: center;
    color: var(--gray-7);

    margin-top: calc(var(--space-default) * -1.125);
    margin-bottom: calc(var(--space-default) * 1.5);

    @include breakpoint-down-xs {
      margin-top: calc(var(--space-s) * -1);
      margin-bottom: var(--space-default);
    }
  }

  &.titled-section {
    margin-inline: calc(var(--space-default) * 5.25);
    padding: calc(var(--space-default) * 2.25) var(--space-xs);

    @include breakpoint-down-s {
      margin-inline: var(--space-m);
      padding: calc(var(--space-default) * 1.5) 0;
    }

    @include breakpoint-down-xs {
      margin-inline: var(--space-default);
      padding: calc(var(--space-default) * 1.5) 0;
    }
  }

  .row:not(:last-child) {
    margin-bottom: calc(var(--space-default) * 1.25);
  }

  .photo-card-content {
    div:first-child {
      @include text-body-medium;
    }

    h4 {
      margin-bottom: 0;
    }

    & > a {
      @include text-label-small;
    }
  }
}

section.collections {
  margin-top: var(--space-xxl);
  position: relative;
  padding: 0 var(--space-m);
  margin-bottom: 0;

  .grid-6 {
    gap: calc(var(--space-default) * 1.7);
  }

  .arrow {
    display: none;
    position: absolute;
    cursor: pointer;
    top: 52%;
    font-size: 20px;
    transform: translateY(-50%);
    transition: opacity 0.6s ease;
  }

  @include breakpoint-down-xs {
    padding: 0;
  }

  .iconoir-nav-arrow-left {
    left: 10px;
  }

  .iconoir-nav-arrow-right {
    right: 10px;
  }

  .collections-items {
    margin-bottom: var(--space-l);
    display: flex;
    overflow-x: auto;
    gap: var(--space-default);

    &::-webkit-scrollbar {
      display: none;
    }

    .marquee-container {
      display: flex;
      align-items: center;
      background-color: black;
      overflow: hidden;
      padding-top: calc(var(--space-unit) * .6);

      @include breakpoint-up-m {
        padding-top: var(--space-unit);
      }

      .marquee {
        font-size: calc(var(--text-base-size) * .9);
        white-space: nowrap;
        margin: 0 auto;

        @include breakpoint-down-s {
          padding-top: calc(var(--space-unit) / 2);
          height: 18px;
        }

        @include breakpoint-up-m {
          font-size: calc(var(--text-base-size) * .7);
        }

        @include breakpoint-up-xl {
          font-size: calc(var(--text-base-size) * .9);
        }

        p {
          @include breakpoint-down-s {
            margin-bottom: -2.4px;
            margin-top: -2px;
          }

          font-weight: 400;
        }
      }
    }

    @keyframes marquee {
      0% {
        transform: translateX(0%);
      }
      100% {
        transform: translateX(-50%);
      }
    }

    .collections-card {
      position: relative;
      flex: 0 0 calc(100% / 6.3);
      width: calc(100% / 6.3);

      @include breakpoint-down-s {
        flex: 0 0 28%;
      }

      @include breakpoint-down-xs {
        flex: 0 0 80%;

        &:first-child {
          margin-left: var(--space-default);
        }

        &:last-child {
          margin-right: var(--space-default);
        }
      }

      img {
        width: 100%;
        aspect-ratio: 2 / 3;
        object-fit: cover;
      }
    }

    .collections-card--coming-soon {
      position: relative;
      overflow: hidden;
      background-color: var(--black);

      .coming-soon-label {
        position: absolute;
        inset: 0;

        display: flex;
        justify-content: center;
        align-items: center;
        padding: var(--space-xs);

        @include text-display-small;
        color: var(--white);
        text-align: center;

        backdrop-filter: blur(7.5px);
        -webkit-backdrop-filter: blur(7.5px);
        background-color: rgba(0, 0, 0, .3);
      }

      .ribbon-black {
        z-index: 1;
      }
    }
  }
}

section.reviews {
  margin-top: var(--space-l);

  .review-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-height: 55em;

    .wrapper {
      overflow: hidden;
    }

    .inner-carousel {
      display: flex;
      transition-delay: 1s;
    }

    .slide {
      flex: 0 0 auto;
      width: calc(100% / 3);
      overflow: hidden;
      flex-direction: row;
      display: flex;
      width: 100%;
      opacity: 0;
      transition: opacity 2.5s linear;
      gap: var(--space-unit);

      &.active {
        opacity: 1;
      }

      .review-card {
        position: relative;
        background-color: var(--black);
        width: 100%;
        height: fit-content;

        .review-card-info {
          .review-name {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            padding: var(--space-xs);
            color: var(--white);
            text-align: center;
            font-weight: 400;
            font-size: calc(var(--text-base-size) * 2.1);

            @include breakpoint-down-l {
              font-size: calc(var(--text-base-size) * 1.5);
            }

            @include breakpoint-down-s {
              font-size: calc(var(--text-base-size) * 1.7);
            }

            @include breakpoint-down-xs {
              font-size: calc(var(--text-base-size) * 2);
            }
          }

          .review-info {
            position: absolute;
            top: 60%;
            transform: translateY(-50%);
            width: 100%;
            padding: var(--space-xs);
            color: var(--white);
            text-align: center;
            font-size: calc(var(--text-base-size) * .9);
            font-weight: 500;

            @include breakpoint-up-xl {
              top: 55%;
            }

            @include breakpoint-down-l {
              font-size: calc(var(--text-base-size) * .5);
            }

            @include breakpoint-down-s {
              font-size: calc(var(--text-base-size) * .7);
            }

            @include breakpoint-down-xs {
              font-size: calc(var(--text-base-size) * .9);
            }
          }
        }

        .review-card-quote {
          display: none;

          .review-quote {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            padding: calc(var(--space-s) * 2);
            color: var(--white);
            text-transform: capitalize;
            text-align: center;
            font-weight: 400;
            font-size: calc(var(--text-base-size) * 1.4);
          }
        }

        img {
          opacity: .7;
          width: 100%;
        }

        &:hover {
          img {
            opacity: .5;
          }

          .review-card-info {
            display: none;
          }

          .review-card-quote {
            display: block;
          }
        }
      }
    }

    .map {
      display: flex;
      justify-content: center;
      margin-top: var(--space-xs);

      .dot {
        width: var(--space-s);
        height: var(--space-s);
        background-color: var(--gray-4);
        border-radius: 50%;
        display: inline-block;
        margin: 0 var(--space-xs);
        cursor: pointer;
      }

      .active {
        background-color: var(--gray-10);
      }
    }
  }
}

section.next-in-line {
  margin-bottom: 0;

  @include breakpoint-up-s {
    display: flex;
    justify-content: center;
  }

  @include breakpoint-down-xs {
    flex-direction: column;
  }

  .next-in-line-title {
    display: flex;
    margin-right: var(--space-m);

    @include breakpoint-down-s {
      flex-wrap: wrap;
      margin-right: 0;
    }

    h5 {
      font-size: calc(var(--text-base-size) * 1.3);

      &:first-child {
        margin-bottom: var(--space-m)
      }

      @include breakpoint-down-s {
        flex: 1 0 100%;
        text-align: center;
        margin: 0 0 var(--space-default);
      }
    }

    .separator {
      font-size: 1.9em;
      line-height: 1.1;
      margin: 0 var(--space-s);

      @include breakpoint-down-s {
        display: none;
      }
    }
  }

  .next-in-line-events {
    display: flex;
    justify-content: center;

    @include breakpoint-down-xs {
      flex-direction: column;
      align-items: center;
    }
  }

  .next-show {
    display: flex;
    flex-direction: column;
    align-items: center;

    .pill {
      white-space: nowrap;
    }

    h6 {
      font-size: calc(var(--text-base-size) * 1.3);
      text-align: center;
      color: var(--gray-7);
      margin-bottom: var(--space-xxs);
    }
  }

  i {
    font-size: calc(var(--text-base-size) * 1.25);
    color: var(--gray-7);
    margin: 0 calc(var(--space-default) * 2.25);

    height: 32px;
    display: flex;
    align-items: center;

    @include breakpoint-down-xs {
      margin: var(--space-default) 0 calc(var(--space-default) - 6px);
      transform: rotate(90deg);
    }
  }
}

section.brands-ranking {
  margin-top: var(--space-xxl);

  & > div.grid-6 {
    gap: calc(var(--space-default) * 1.125);

    .brands-ranking-legend {
      @include breakpoint-up-m {
        padding-right: var(--space-s);
      }

      a {
        text-decoration: underline;

        @include breakpoint-down-s {
          align-items: center;
          justify-content: center;
        }
      }
    }

    @include breakpoint-down-s {
      display: flex;
      flex-direction: column;
      gap: var(--space-xs);
      margin-inline: var(--space-xs);
    }

    & > div {
      a {
        text-decoration: none;
        max-height: 45px;
        height: auto;
        min-height: 25px;
        display: flex;
        line-height: 15px;

        @include breakpoint-down-s {
          grid-row: 1;
          grid-column: span 2;
          align-items: end;
          min-width: 150px;
          display: flex;
        }
      }

      @include breakpoint-down-l {
        @include text-label-medium;
      }

      @include breakpoint-down-s {
        &:first-child {
          text-align: center;
        }
      }

      &:not(:last-child) {
        border-right: 1px solid var(--gray-5);
        padding-right: var(--space-xxs);

        @include breakpoint-down-s {
          border-right: none;
        }
      }

      &:first-child {
        @include text-body-medium;

        @include breakpoint-down-l {
          @include text-body-small;
        }
      }
    }
  }

  .rank {
    display: flex;
    gap: var(--space-xxs);

    @include breakpoint-down-l {
      .rank-graph-container a {
        max-height: 25px;
        min-height: 5px;
        height: auto;
      }
    }

    @include breakpoint-down-s {
      border-top: 1px solid var(--gray-8);
      grid-template-columns: repeat(4, auto);
      grid-template-rows: repeat(1, auto);
      grid-gap: var(--space-s);
      padding-top: var(--space-xs);
      display: flex;

      .rank-graph-container {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
      }

      & > span {
        align-self: center;
      }
    }

    .rank-number {
      font-variant-numeric: lining-nums proportional-nums;
      font-family: var(--font-secondary);
      font-size: calc(var(--text-base-size) * 5.5);
      font-weight: 700;
      line-height: 0.73;
      letter-spacing: -.25px;

      @include breakpoint-down-l {
        font-size: calc(var(--text-base-size) * 4.5);
      }

      @include breakpoint-down-s {
        font-size: calc(var(--text-base-size) * 3);
        grid-row: 1;
        grid-column: span 1;
        width: 20px;
      }
    }

    .brand {
      @include text-title-small;

      @include breakpoint-down-l {
        font-size: calc(var(--text-base-size) * .8);
      }

      @include breakpoint-down-s {
        margin-top: 5px;
      }
    }

    .rank-evolution {
      font-size: calc(var(--text-base-size) * 1.3);
      font-weight: 500;
      display: flex;
      align-items: center;

      @include breakpoint-down-l {
        font-size: calc(var(--text-base-size) * 1);
        letter-spacing: -.25px;
        font-weight: 400;

        &.up::before {
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 6px solid var(--color-success);
          margin-top: -5px;
        }
      }

      @include breakpoint-down-s {
        padding-top: 5px;
        display: flex;
        justify-content: right;
        grid-row: 1;
        grid-column: span 1;
        font-size: calc(var(--text-base-size) * 1.5);
      }

      &.up {
        color: var(--color-success);

        svg line {
          stroke: var(--color-success);
        }

        &::before {
          content: '';
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 9px solid var(--color-success);
          margin-top: -5px;
          margin-right: var(--space-xxs);
        }
      }

      &.down {
        color: var(--color-error);
        svg line {
          stroke: var(--color-error);
        }
        &::before {
          content: '';
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-top: 9px solid var(--color-error);
          margin-top: -4px;
          margin-right: var(--space-xxs);
        }
      }

      &.same {
        color: var(--color-primary-1);

        svg line {
          stroke: var(--color-primary-1);
        }
      }

      .chart {
        margin-left: var(--space-xs);
        width: 40%;
        height: 45px;
        line {
          stroke-linecap: round;
        }
        @include breakpoint-down-s {
          line {
            stroke-width: 5.5;
            &:last-child {
              stroke-width: 0;
            }
          }
        }
        @include breakpoint-down-s {
          line {
            stroke-width: 5.5;
            &:last-child {
              stroke-width: 0;
            }
          }
        }
      }
    }
  }
}

section.homepage-banner-promo {
  .grid-2 {
    @include breakpoint-down-xs {
      display: flex;
      overflow: auto;
    }
  }

  a {
    @include breakpoint-down-xs {
      flex: 1 0 90%;
    }
  }

  .banner {
    aspect-ratio: 1.25/1;
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .1);
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 0;
    }

    h5 {
      z-index: 1;
      position: absolute;
      white-space: break-spaces;
      width: 100%;
      text-align: center;
      top: 4%;
      color: var(--white);

      @include breakpoint-down-s {
        font-size: calc(var(--text-base-size) * 1.5);
      }

      @include breakpoint-down-xs {
        font-size: var(--text-base-size);
      }
    }
  }
}

section.trends {
  margin-bottom: var(--space-l);
}

section.newsletter {
  margin: 0 calc(var(--space-default) * 5.25) var(--space-m);
  padding: calc(var(--space-default) * 1.5);

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @include breakpoint-down-s {
    margin-inline: var(--space-m);
    padding: var(--space-s) var(--space-xxs);

    flex-direction: column;
    gap: var(--space-default);
  }

  @include breakpoint-down-xs {
    margin-inline: var(--space-default);
  }

  div:first-child {
    h3 {
      margin-bottom: var(--space-xs);
    }

    p {
      font-family: var(--font-secondary);
      font-weight: 400;
    }
  }

  .newsletter-form {
    form {
      min-width: 545px;

      @include breakpoint-down-xs {
        min-width: auto;
        width: 100%;
      }
    }
  }
}

.tds section.insights {
  padding: var(--space-l);

  @include breakpoint-down-s {
    padding: var(--space-l) var(--space-xs);
  }

  @include breakpoint-down-xs {
    padding: calc(var(--space-default) * 1.5) var(--space-xs);

    p {
      @include text-body-small;
    }
  }

  div[class^=grid] {
    @include breakpoint-down-xs {
      display: flex;
      overflow-x: auto;
    }

    & > div {
      flex: 1 0 90%;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  img {
    height: 270px;
    margin-bottom: calc(var(--space-default) * 1.5);

    object-fit: cover;

    @include breakpoint-down-s {
      height: 172px;
    }

    @include breakpoint-down-xs {
      height: 165px;
    }
  }

  h3, p {
    margin-bottom: var(--space-s);
  }

  a.btn {
    margin-top: auto;
  }
}

.growing-tags {
  padding-inline: var(--space-l);

  @include breakpoint-down-s {
    margin-bottom: var(--space-default);
  }

  h3 {
    margin-bottom: calc(var(--space-default) * 1.125);
  }

  a {
    text-decoration: none;
  }

  label {
    @include text-label-large;
    color: var(--gray-7);
    text-transform: uppercase;

    margin-bottom: var(--space-xxs);

    @include breakpoint-down-xs {
      margin-bottom: 0;
      @include text-label-small;
      justify-self: start;
    }
  }

  h5 {
    margin-bottom: var(--space-xxs);

    @include breakpoint-down-xs {
      font-size: calc(var(--text-base-size) * 1.35);
    }
  }

  .flex-space-between {
    @include breakpoint-down-xs {
      flex-direction: column;
    }

    div {
      flex-grow: 1;

      @include breakpoint-down-xs {
        width: 100%;
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 2fr 1fr;
        padding-block: var(--space-xs);

        span {
          justify-self: end;
        }

        &:not(:last-child) {
          border-bottom: 1px solid var(--color-border);
        }

        &:last-child {
          padding-bottom: 0;
        }
      }

      &:not(:last-child) {
        border-right: 1px solid var(--color-border);

        @include breakpoint-down-xs {
          border-right: none;
        }
      }

      span {
        display: flex;
        justify-content: center;
        align-items: baseline;

        color: var(--color-success);
        font-weight: 500;

        &::before {
          content: '';
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 9px solid var(--color-success);
          margin-top: -5px;
          margin-right: var(--space-xxs);
        }
      }
    }
  }
}

.main-news img {
  height: 100%;
  width: 100%;
  object-fit: cover;

  @include breakpoint-down-xs {
    height: 360px;
  }
}

.tds section.whats-new {
  padding: var(--space-l) calc(var(--space-default) * 1.5);

  @include breakpoint-down-s {
    padding: var(--space-m) 0;

    div[class*=grid] {
      display: flex;
      overflow-x: auto;
    }
  }

  @include breakpoint-down-xs {
    padding: calc(var(--space-default) * 1.5) 0;
  }

  .row {
    margin-bottom: 0;

    @include breakpoint-down-s {
      flex: 1 0 65%;
      display: flex;
      column-gap: var(--space-s);
    }

    @include breakpoint-down-xs {
      flex: 1 0 90%;
    }

    a {
      @include text-body-small;
    }
  }

  .col-1-of-3 {
    @include breakpoint-down-s {
      flex: 0 0 33%;
      aspect-ratio: 1 / 1;
    }
  }

  .col-2-of-3 {
    flex: 0 1 66%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  h6 {
    margin-bottom: auto;

    @include breakpoint-down-xs {
      font-size: calc(var(--text-base-size) * 1.125);
    }
  }

  label {
    margin-top: var(--space-s);
  }

  label, span {
    @include text-label-small;
    text-transform: uppercase;
  }
}

section.models {
  text-align: center;

  div[class*=grid] {
    @include breakpoint-down-xs {
      display: flex;
      overflow-x: auto;

      & > a {
        flex: 1 0 70%;
      }
    }
  }

  h2 {
    text-transform: none;
  }

  img {
    margin-bottom: calc(var(--space-default) * 1.125);

    @include breakpoint-down-xs {
      margin-bottom: var(--space-xs);
    }
  }

  h6 {
    margin-bottom: var(--space-xs);
  }

  a {
    text-decoration: none;
  }
}

section.streetstyles {
  .container-first-streetstyle, .container-second-streetstyle {
    display: grid;
    gap: var(--space-s);
    grid-template-rows: repeat(2, 1fr);
  }

  .container-first-streetstyle {
    grid-template-columns: repeat(10, 1fr);
    grid-template-areas:
      "small1 large1 large1 small3 large2 large2 small5 large3 large3 small7"
      "small2 large1 large1 small4 large2 large2 small6 large3 large3 small8";

    .item:nth-child(1) {
      grid-area: small1;
    }

    .item:nth-child(2) {
      grid-area: small2;
    }

    .item:nth-child(3) {
      grid-area: large1;
    }

    .item:nth-child(4) {
      grid-area: small3;
    }

    .item:nth-child(5) {
      grid-area: small4;
    }

    .item:nth-child(6) {
      grid-area: large2;
    }

    .item:nth-child(7) {
      grid-area: small5;
    }

    .item:nth-child(8) {
      grid-area: small6;
    }

    .item:nth-child(9) {
      grid-area: large3;
    }

    .item:nth-child(10) {
      grid-area: small7;
    }

    .item:nth-child(11) {
      grid-area: small8;
    }
  }

  .container-second-streetstyle {
    grid-template-columns: repeat(8, 1fr);
    grid-template-areas:
    "large1 large1 small1 large2 large2 small3 large3 large3"
    "large1 large1 small2 large2 large2 small4 large3 large3";
    margin-top: var(--space-s);

    .item:nth-child(1) { grid-area: large1; }
    .item:nth-child(2) { grid-area: small1; }
    .item:nth-child(3) { grid-area: small2; }
    .item:nth-child(4) { grid-area: large2; }
    .item:nth-child(5) { grid-area: small3; }
    .item:nth-child(6) { grid-area: small4; }
    .item:nth-child(7) { grid-area: large3; }
  }

  img {
    width: 100%;
    object-fit: cover;
  }

  @include breakpoint-down-s
  {
    .container-first-streetstyle, .container-second-streetstyle {
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      ::-webkit-scrollbar {
        display: none;
      }
    }

    .container-first-streetstyle {
      grid-template-columns: repeat(10, 30%);
    }

    .container-second-streetstyle {
      grid-template-columns: repeat(8, 30%);
    }
  }
}

section.tag-book {
  .subtitle {
    margin-top: 0;
  }

  .btn-compact {
    margin-left: var(--space-s);
  }

  .marquee-container {
    display: flex;
    align-items: center;
    background-color: black;
    overflow: hidden;

    .marquee {
      white-space: nowrap;
      margin: 0 auto;

      p {
        font-weight: 400;
      }

      @keyframes marquee {
        0% {
          transform: translateX(0%);
        }
        100% {
          transform: translateX(-50%);
        }
      }
    }
  }

  div.grid-8 {
    a:nth-child(n+5) {
      overflow: auto;
      display: block;
    }

    &::after {
      content: '';
      grid-column: span 4;
    }

    @include breakpoint-down-xs {
      grid-template-columns: repeat(4, 45%);
      overflow-x: auto;
    }

    & > a {
      position: relative;

      @include breakpoint-down-xs {
        flex: 1 0 28%;
      }

      h6 {
        position: absolute;
        width: 100%;
        bottom: 0;
        padding: 0 var(--space-xxs);

        text-align: center;
        color: var(--white);
        text-shadow: 0 0 18px #000, 0 0 10.2px rgba(0, 0, 0, 0.50);
        font-size: calc(var(--text-base-size) * 1.125);
        text-transform: capitalize;
      }

      .ribbon-black {
        position: absolute;
        width: 100%;
        bottom: 0;
        @include text-label-medium;
        padding: var(--space-xxs) var(--space-unit) calc(var(--space-unit) / 2);

        @include breakpoint-down-s {
          height: 24px;
        }

        .marquee {
          p {
            margin-top: -2px;
          }
        }
      }
    }
  }

  .grid-container {
    display: grid;
    grid-gap: 16px;
    padding: 16px;
    grid-template-columns: repeat(auto-fill,minmax(160px,1fr));
    grid-auto-flow: column;
    grid-auto-columns: minmax(160px,1fr);
    overflow-x: auto;
  }

  .item {
    background-color: lightblue;
    padding: 20px;
    text-align: center;
    width: 50%;
  }
}
