@import '../../defaults/typography';

.tds {
  .poll {
    @extend .box;
    align-items: center;

    text-align: center;

    h6 {
      margin-bottom: calc(var(--space-default) * 1.5);
    }

    h6 + p {
      @include text-body-small;
      color: var(--gray-8);

      margin-top: calc(var(--space-xs) * -1);
      margin-bottom: var(--space-s);
    }

    .poll-choice {
      font-family: var(--font-secondary);
      font-size: calc(var(--text-base-size) * .875);
      line-height: 1.33;
      background-color: var(--gray-2);

      padding: var(--space-xxs) var(--space-default);
      margin-bottom: var(--space-s);
      align-self: stretch;
      display: flex;
      justify-content: center;
    }

    button {
      margin-top: var(--space-m);
    }

    &:not(.poll--answered) .poll-choice {
      cursor: pointer;
      border: 1px solid var(--gray-2);

      &.poll-choice--selected {
        background-color: var(--gray-1);
        border-color: var(--black);
      }
    }

    &.poll--answered {
      h6 + p,
      button {
        display: none;
      }
    }
  }
}
